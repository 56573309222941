body {
  margin: 0;
  padding: 0;
}

.custom-login .MuiCard-root {
  /* 
  Julien : On small devices in the Login Screen
  The Login button was hidden because of the overflow: hidden;
  set by default from MUI.
  */
  overflow: auto;
}
