.importContainer {
    position: relative;
    cursor: pointer;
}

.importInput {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.0001;
    cursor: pointer;
}

.importInput::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer;
}
